import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import config from './config/config';
import Logo from './img/logo_goji_dark.png';
import handleLogout from './functions/functions';
import PoweredByStripe from './img/powered_by_stripe.png';

const stripePromise = loadStripe('pk_test_51ORmOjFziCzDV6ZvDsIb5CUtT31McWl2PCx4rNDaWnks9zWtUOb6SIm5bJXoWS3m55FiiIqrGEM9X63uggpjVrp900OYXIbOms');

type PaymentType = 'Gratis' | 'Standard' | 'Premium';

const CheckoutForm: React.FC<{ onSubscriptionCreated: (success: boolean) => void }> = ({ onSubscriptionCreated }) => {
    const [gojimxName, setGojimxName] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string | null>(null);
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setGojimxName(String(localStorage.getItem('gojimx_username')));
    }, []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            setError('Stripe.js has not loaded yet.');
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const { paymentMethod, error: paymentError } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
            billing_details: {
                name: gojimxName,
            },
        });

        if (paymentError) {
            setError(String(paymentError.message));
            setProcessing(false);
            return;
        }

        try {
            const token = localStorage.getItem('gojimx_token');
            const businessId = localStorage.getItem('gojimx_businessId');
            const paymentType = localStorage.getItem('gojimx_payment_type') || 'Gratis';

            let priceId;
            if (paymentType === 'Standard') {
                priceId = 'price_1PsY8oFziCzDV6Zvzh2WYLcC'; // ID de precio para Renata Standard
            } else if (paymentType === 'Premium') {
                priceId = 'price_1PsYAnFziCzDV6Zvpw3D3W8h'; // ID de precio para Renata Premium
            }

            if (priceId && paymentMethod) {
                const response = await axios.post(
                    `${config.backendURL}/create_subscription`,
                    {
                        business_id: businessId,
                        paymentMethodId: paymentMethod.id,
                        plan: priceId,  // Utiliza el ID del precio
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.subscriptionId) {
                    setSucceeded(true); // Set the succeeded state here
                    onSubscriptionCreated(true);
                } else {
                    setError('Error creating subscription: Invalid response from server');
                    onSubscriptionCreated(false);
                }
            } else {
                setError('Payment or Price ID missing');
            }
        } catch (error) {
            setError('Error creating subscription');
            onSubscriptionCreated(false);
        }

        setProcessing(false);
    };

    return (
        <Form onSubmit={handleSubmit} className="checkout-form">
            <Form.Group className="mb-3 mt-2 text-start">
                <Form.Label className='text-start ms-1'>Nombre:</Form.Label>
                <Form.Control
                    className='card-element-wrapper'
                    type="text"
                    placeholder="Ingrese su nombre"
                    value={gojimxName}
                    onChange={(e) => setGojimxName(e.target.value)}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3 mt-4 text-start">
                <Form.Label className='text-start ms-1'>Número de Tarjeta:</Form.Label>
                <CardNumberElement className="card-element-wrapper" />
            </Form.Group>
            <Form.Group className="mb-3 text-start">
                <Form.Label className='text-start ms-1'>Fecha de Expiración:</Form.Label>
                <CardExpiryElement className="card-element-wrapper" />
            </Form.Group>
            <Form.Group className="mb-3 text-start">
                <Form.Label className='text-start ms-1'>CVC:</Form.Label>
                <CardCvcElement className="card-element-wrapper" />
            </Form.Group>
            <Button type="submit" variant="dark" disabled={!stripe || processing} className="w-100">
                {processing ? <Spinner animation="border" size="sm" /> : <span><i className="bi bi-lock-fill me-2"></i>Pagar Ahora </span>}
            </Button>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {succeeded && <Alert variant="success" className="mt-3">¡Transacción completada!</Alert>}
        </Form>
    );
};

const Payment: React.FC = () => {
    const [showModal, setShowModal] = useState(true);
    const [succeeded, setSucceeded] = useState(false); // Add this state

    const handleSubscriptionCreated = (success: boolean) => {
        if (success) {
            setSucceeded(true); // Use the setSucceeded function here
            // Pago succesful
            localStorage.setItem("gojimx_needs_payment", "false")

            setTimeout(() => {
                window.location.replace('/home');
            }, 2000);
        }
    };

    const paymentType = (localStorage.getItem('gojimx_payment_type') || 'Gratis') as PaymentType;
    const { price, features } = {
        Gratis: {
            price: '$0',
            features: [
                '1 número de WhatsApp',
                '1 asistente Chat',
                '1 Acceso Renata-APP',
                '3,000 puntos',
            ],
        },
        Standard: {
            price: '$990',
            features: [
                '3 Números de WhatsApp',
                '3 Asistentes Chat',
                '3 Accesos Renata-APP',
                'Contesta 24/7',
                '55,000 puntos',
            ],
        },
        Premium: {
            price: '$2990',
            features: [
                '6 Números de WhatsApp',
                '6 Asistentes Chat y Llamada',
                '6 Accesos Renata-APP',
                'Contesta 24/7',
                'Soporte Técnico',
                '180,000 puntos',
            ],
        },
    }[paymentType];

    return (
        <Elements stripe={stripePromise}>
            <Modal show={showModal} size="lg" centered>
                <Modal.Header className="d-flex justify-content-center border-0 bg-payment-custom rounded">
                    <img className="w-20" src={Logo} alt="GOJI LOGO" />
                </Modal.Header>
                <Modal.Body className='mt-0'>
                    <div className='px-5 py-4 text-center'>
                        <div className="row">
                            <div className="col-6 text-start d-flex flex-column">
                                <h3>Resumen de compra</h3>
                                <h1 className='display-4'>{price} <span className='text-secondary' style={{ fontSize: '0.50em' }}>MXN/mes</span></h1>
                                <span className='fw-bold mb-2'>Incluye:</span>
                                {features.map((feature, index) => (
                                    <p key={index} className='py-1 my-0'><span>• {feature}</span></p>
                                ))}
                                <p className="mt-auto">
                                    Al realizar esta compra, aceptas nuestros <a className='text-primary' href="https://goji.mx/terms">términos de servicio</a> y <a className='text-primary' href="https://goji.mx/privacy">política de privacidad</a>.
                                </p>
                            </div>
                            <div className="col-6 border-start text-start">
                                <div className="row justify-content-end">
                                    <div className="col-11">
                                        <h3>Detalles de la Tarjeta</h3>
                                        {paymentType !== 'Gratis' && (
                                            <CheckoutForm onSubscriptionCreated={handleSubscriptionCreated} />
                                        )}
                                        {paymentType === 'Gratis' && (
                                            <Alert variant="success" className="mt-3">
                                                ¡Este plan es gratuito, no se requiere información de pago!
                                            </Alert>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col-6">
                            <img className="w-50" src={PoweredByStripe} alt="Powered By Stripe" style={{ opacity: 0.5 }} />
                        </div>
                        <div className="col-6 text-end">
                            <Button className="text-danger text-start" variant="light" onClick={handleLogout}>Cerrar Sesión</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </Elements>
    );
};

export default Payment;